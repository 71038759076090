import {
    HttpClient,
    HttpErrorResponse,
    HttpRequest,
} from '@angular/common/http';
import {
    EventEmitter,
    Inject,
    Injectable,
    Optional,
    signal,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { MODULE_ACCESS_TOKEN } from '@core/tokens/module-access-token.token';
import { Profile } from '@models/profile';
import { TranslateService } from '@ngx-translate/core';
import { Login } from '@public/interfaces/login.interface';
import { User } from '@shared/models/user';
import { AuthService } from 'ngx-auth';
import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    ReplaySubject,
    catchError,
    mergeMap,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import { ServerError } from '../../exceptions/error';
import { ProfileService } from '../profile/profile.service';
import { AuthSettingsService } from './auth-settings.service';
import { TokenStorageService } from './token-storage.service';

interface AccessData {
    accessToken: string;
    accessTokenExp: string;
    expiryTimestamp: number;
    roleable_name: string;
    roleable_id: string;
    global_view: boolean;
}

interface Response {
    code: number;
    message: string;
    payload: any;
}

interface LoginResponse {
    token: {
        access_token: string;
        expires_in: string;
        expiry_timestamp: number;
    };
    active_role: {
        name: string;
        pivot: {
            roleable_id: number;
        };
    };
    user: User;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements AuthService {
    public isAuthSubject = new ReplaySubject<boolean>();
    public loggedIn = new EventEmitter<any>();
    public user;
    private refreshObservable: Observable<any>;
    /**
     * expiryTimestamp is static because AuthenticationService is provided multiple times
     * Only one instance of this variable is needed, otherwise session renew does not work
     */
    public static expiryTimestamp = signal(0);

    constructor(
        private httpClient: HttpClient,
        private tokenStorage: TokenStorageService,
        private router: Router,
        private authSettings: AuthSettingsService,
        private profileService: ProfileService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        @Optional() @Inject(MODULE_ACCESS_TOKEN) private moduleToken?: string,
    ) {
        AuthenticationService.expiryTimestamp.set(
            this.tokenStorage.getAccessTokenExpTimestamp(this.moduleToken),
        );
    }

    private static checkErrorIsTokenExpired(
        response: HttpErrorResponse,
    ): boolean {
        return (
            response.status === 401 &&
            response.error?.exceptionCode === 'tokenExpired'
        );
    }

    public login(data: Login): Observable<any> {
        return this.httpClient.post('login', data).pipe(
            tap((response: ResponseGeneric<LoginResponse>) => {
                if (response.payload.user) {
                    this.user = response.payload.user;
                    const accessToken = response.payload.token.access_token;
                    const accessTokenExp = response.payload.token.expires_in;
                    const expiryTimestamp =
                        response.payload.token.expiry_timestamp;
                    const { roles, permissions, active_role } = this.user;

                    if (roles && permissions) {
                        this.authSettings.updateRoles(roles);
                        this.authSettings.updatePermissions(permissions);
                    }

                    let roleable_name;
                    let roleable_id;
                    if (response.payload.active_role) {
                        roleable_name = response.payload.active_role.name;
                        roleable_id =
                            response.payload.active_role.pivot.roleable_id;
                    } else if (active_role) {
                        roleable_name = active_role.roleable_name;
                        roleable_id = active_role.roleable_id;
                    }

                    this.saveAccessData({
                        accessToken,
                        accessTokenExp,
                        expiryTimestamp,
                        global_view: false,
                        roleable_name,
                        roleable_id,
                    });

                    AuthenticationService.expiryTimestamp.set(expiryTimestamp);
                    this.loggedIn.emit(this.user);
                }
            }),
        );
    }

    public isAuthorized(): Observable<boolean> {
        const isAuthorized: boolean = this.tokenStorage.checkAccessToken(
            this.moduleToken,
        );
        this.isAuthSubject.next(isAuthorized);
        return of(isAuthorized);
    }

    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken(this.moduleToken);
    }

    public refreshToken(): Observable<any> {
        if (this.refreshObservable) {
            return this.refreshObservable;
        }

        this.refreshObservable = this.httpClient.post('refresh', {}).pipe(
            catchError((err) => {
                if (err.error.message === 'token_expired') {
                    this.toastrService.warning(
                        this.translateService.instant(
                            'ERROR.SESSION_INVALID_LOGIN_PLEASE',
                        ),
                    );
                } else {
                    this.toastrService.error(
                        this.translateService.instant(
                            new ServerError().message,
                        ),
                    );
                    console.error(err);
                }
                this.refreshObservable = null;

                return of(this.logout()).pipe(
                    mergeMap(() => throwError(() => err)),
                );
            }),
            tap((res: Response) => {
                this.refreshObservable = null;
                const accessToken = res.payload.access_token;
                const accessTokenExp = res.payload.expires_in;
                const expiryTimestamp = res.payload.expiry_timestamp;
                AuthenticationService.expiryTimestamp.set(expiryTimestamp);

                const roleable_name = res.payload.roleable_name;
                const roleable_id = res.payload.roleable_id;
                const global_view =
                    this.tokenStorage.getAccessTokenSync('Global-View') ==
                    'true';

                if (res.payload.user?.roles && res.payload.permissions) {
                    const { roles, permissions } = res.payload.user;
                    this.authSettings.updateRoles(roles);
                    this.authSettings.updatePermissions(permissions);
                }

                this.saveAccessData({
                    accessToken,
                    accessTokenExp,
                    expiryTimestamp,
                    global_view,
                    roleable_name,
                    roleable_id,
                });
            }),
        );
        return this.refreshObservable;
    }

    public refreshTokenIfAuthorized(): void {
        this.isAuthorized()
            .pipe(
                take(1),
                switchMap((isAuthorized) => {
                    if (isAuthorized) {
                        this.refreshObservable = null;
                        return this.refreshToken();
                    }
                    return of(null);
                }),
                catchError((err) => {
                    return of(err);
                }),
            )
            .subscribe();
    }

    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        if (this.tokenStorage.getAccessTokenSync(this.moduleToken)) {
            return (
                this.hasTokenExpired() ||
                AuthenticationService.checkErrorIsTokenExpired(response)
            );
        }
        if (!this.isLoginOrRegisterUrl()) {
            this.logout();
        }
        return false;
    }

    public verifyRefreshToken(request: HttpRequest<any>): boolean {
        return request.url.endsWith('refresh');
    }

    public logout(navigateTo?: string[], state?: NavigationExtras): void {
        if (navigateTo) {
            this.tokenStorage.clearAll();
            this.router.navigate(navigateTo, state);
            return;
        }

        this.profileService.getProfile().subscribe({
            next: (profile: Profile) => {
                this.tokenStorage.clearAll();
                if (profile.origin === 'external') {
                    this.router.navigate(['logout'], state);
                } else {
                    this.authSettings.path$.pipe(take(1)).subscribe((path) => {
                        if (path) {
                            this.router.navigate([`${path}/login`], {
                                queryParams: {
                                    token_type: null,
                                    access_token: null,
                                    expire: null,
                                },
                                queryParamsHandling: 'merge',
                            });
                        } else {
                            this.router.navigate([`/login`], {
                                queryParams: {
                                    token_type: null,
                                    access_token: null,
                                    expire: null,
                                },
                                queryParamsHandling: 'merge',
                            });
                        }
                    });
                }
                this.profileService.clearCache();
            },
            error: () => {
                this.tokenStorage.clearAll();
                this.router.navigate([`/login`]);
            },
        });
    }

    public hasTokenExpired(): boolean {
        return (
            !this.tokenStorage.checkAccessTokenExpiration(this.moduleToken) &&
            this.tokenStorage.checkAccessToken(this.moduleToken)
        );
    }

    public isLoginOrRegisterUrl(): boolean {
        return (
            this.router.url &&
            (this.router.url.includes('login') ||
                this.router.url.includes('register') ||
                this.router.url.includes('forgot-password') ||
                this.router.url.includes('reset') ||
                this.router.url == '/')
        );
    }

    public saveAccessData({
        accessToken,
        accessTokenExp,
        expiryTimestamp,
        global_view,
        roleable_name,
        roleable_id,
    }: AccessData): void {
        this.tokenStorage.setAccessToken(String(global_view), 'Global-View');

        this.tokenStorage.setAccessToken(accessToken, 'supplier_access_token');
        this.tokenStorage.setAccessToken(accessToken, 'company_access_token');
        this.tokenStorage.setAccessToken(accessToken, 'employee_access_token');
        this.tokenStorage.setAccessToken(accessToken, 'access_token');

        this.tokenStorage.setAccessTokenExpiration(
            accessTokenExp,
            'supplier_access_token',
        );
        this.tokenStorage.setAccessTokenExpiration(
            accessTokenExp,
            'company_access_token',
        );
        this.tokenStorage.setAccessTokenExpiration(
            accessTokenExp,
            'employee_access_token',
        );
        this.tokenStorage.setAccessTokenExpiration(
            accessTokenExp,
            'access_token',
        );

        this.tokenStorage.setAccessTokenExpiryTimestamp(
            expiryTimestamp,
            'supplier_access_token',
        );
        this.tokenStorage.setAccessTokenExpiryTimestamp(
            expiryTimestamp,
            'company_access_token',
        );
        this.tokenStorage.setAccessTokenExpiryTimestamp(
            expiryTimestamp,
            'employee_access_token',
        );
        this.tokenStorage.setAccessTokenExpiryTimestamp(
            expiryTimestamp,
            'access_token',
        );

        if (roleable_name) {
            this.tokenStorage.setAccessToken(roleable_name, 'Role-Name');
        }
        if (roleable_id) {
            this.tokenStorage.setAccessToken(roleable_id, 'Roleable-Id');
        }
    }
}
