import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'dd-info-tooltip',
    standalone: true,
    imports: [CommonModule, MatTooltipModule],
    templateUrl: './info-tooltip.component.html',
})
export class InfoTooltipComponent {
    public text = input.required<string>();
}
