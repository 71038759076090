<button
    type="button"
    data-testid="info-tooltip-button"
    class="btn d-inline-flex p-0"
    [attr.aria-label]="text()"
    [matTooltip]="text()"
>
    <i
        class="fal fa-info-circle fs-5"
        aria-hidden="true"
    ></i>
</button>
