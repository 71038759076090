import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FileUploadModule } from '@app/ui-component/file-upload/file-upload.module';
import { InfoTooltipComponent } from '@app/ui-component/info-tooltip/info-tooltip.component';
import { AuditsDetailComponent } from '@leasing-details/audits-detail/audits-detail.component';
import { ModalsModule } from '@modals/modals.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectComponent } from '@shared/components/multi-select/multi-select.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    DoughnutController,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { provideCharts } from 'ng2-charts';
import { ImageComponent } from '../app/ui-component/image/image.component';
import { FormItemFieldComponent } from './components/form-item-field/form-item-field.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { PagerComponent } from './components/pager/pager.component';
import { PdfUploaderComponent } from './components/pdf-uploader/pdf-uploader.component';
import { SelectBeamtenStatusComponent } from './components/select-beamten-status/select-beamten-status.component';
import { StatusStepsComponent } from './components/status-steps/status-steps.component';
import { StatusComponent } from './components/status/status.component';
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { NgSelectComboboxDirective } from './directives/ng-select-combobox.directive';
import { SetFormItemFieldInputAttributesDirective } from './directives/set-form-item-field-input-attributes.directive';
import { CompareNumberDirective } from './directives/validators/compare-number.directive';
import { DomainDirective } from './directives/validators/domain.directive';
import { EmailDirective } from './directives/validators/email.directive';
import { EqualValidator } from './directives/validators/equal-validator.directive';
import { MaxDirective } from './directives/validators/max.directive';
import { MinDirective } from './directives/validators/min.directive';
import { NumberDirective } from './directives/validators/number.directive';
import { PasswordDirective } from './directives/validators/password.directive';
import { PhoneDirective } from './directives/validators/phone.directive';
import { RequiredDirective } from './directives/validators/required.directive';
import { AsyncCityPipe } from './pipes/async-city.pipe';
import { CityPipe } from './pipes/city.pipe';
import { CustomPercentPipe } from './pipes/custom-percent.pipe';
import { DecodeUriFilePipe } from './pipes/decode-uri-file.pipe';
import { FileSizeInMbPipe } from './pipes/file-size.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { ListFilterPipe } from './pipes/list-filter.pipe';
import { RejectedUserPipe } from './pipes/rejected-user.pipe';
import { StatusTabsComponent } from './table-utilities/status-tabs/status-tabs.component';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        ModalsModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FileUploadModule,
        NgSelectModule,
        ImageComponent,
        InfoTooltipComponent,
        ValidationErrorsComponent,
    ],
    declarations: [
        FormItemFieldComponent,
        StatusComponent,
        StatusStepsComponent,
        RejectedUserPipe,
        CustomPercentPipe,
        CityPipe,
        PasswordDirective,
        RequiredDirective,
        DomainDirective,
        EmailDirective,
        MaxDirective,
        MinDirective,
        NumberDirective,
        CompareNumberDirective,
        PhoneDirective,
        EqualValidator,
        ImageUploaderComponent,
        PdfUploaderComponent,
        CurrencyMaskDirective,
        DecodeUriFilePipe,
        AuditsDetailComponent,
        FileSizeInMbPipe,
        StatusTabsComponent,
        FormatStringPipe,
        ClickOutsideDirective,
        ListFilterPipe,
        MultiSelectComponent,
        SelectBeamtenStatusComponent,
        PagerComponent,
        AsyncCityPipe,
        FocusInvalidInputDirective,
        NgSelectComboboxDirective,
        SetFormItemFieldInputAttributesDirective,
    ],
    exports: [
        FormItemFieldComponent,
        ValidationErrorsComponent,
        StatusComponent,
        StatusStepsComponent,
        RejectedUserPipe,
        CustomPercentPipe,
        CityPipe,
        PasswordDirective,
        RequiredDirective,
        DomainDirective,
        EmailDirective,
        MaxDirective,
        MinDirective,
        PhoneDirective,
        NumberDirective,
        CompareNumberDirective,
        EqualValidator,
        ImageUploaderComponent,
        PdfUploaderComponent,
        CurrencyMaskDirective,
        DecodeUriFilePipe,
        AuditsDetailComponent,
        FileSizeInMbPipe,
        StatusTabsComponent,
        FormatStringPipe,
        ClickOutsideDirective,
        ListFilterPipe,
        MultiSelectComponent,
        SelectBeamtenStatusComponent,
        PagerComponent,
        AsyncCityPipe,
        NgSelectComboboxDirective,
        FocusInvalidInputDirective,
        SetFormItemFieldInputAttributesDirective,
    ],
    providers: [
        CurrencyPipe,
        ListFilterPipe,
        DatePipe,
        [
            provideCharts({
                registerables: [
                    BarController,
                    LineController,
                    DoughnutController,
                    LinearScale,
                    CategoryScale,
                    BarElement,
                    PointElement,
                    LineElement,
                    ArcElement,
                    Legend,
                    Tooltip,
                    Title,
                ],
            }),
        ],
    ],
})
export class SharedModule {}
